import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
dayjs.locale('es');

function lightOrDark(color) {
  const hexColor = (color ?? "").replace(/^#/, "");
  const [r, g, b] = hexColor.match(/.{1,2}/g).map((str) => parseInt(str, 16));
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 127.5;
}

function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const [dayIndex, setDayIndex] = useState(0);
  const [selectedEventViewport, setSelectedEventViewport] = useState([]);
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);

  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    labels
  } = useContext(GlobalContext);

  useEffect(() => {
    const events = filteredEvents.filter(
      (event) => labels.filter((label) => label.label === event.color && label.checked === false).length === 0
    )
      .filter(
        (event) => dayjs(event.date).format("DD-MM-YY") === day.format("DD-MM-YY")
      );
      
    setDayEvents(events);
    setSelectedEventViewport([]);
    
    if (events.length > 0) {
      setSelectedEventViewport([events[selectedEventIndex]]);
    }
  }, [filteredEvents, day, labels]);

  const getCurrentDayClass = () => day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "bg-white text-gray-600 rounded-lg w-7" : "";

  const handleNextEvent = (e) => {
    e.stopPropagation();
    const nextIndex = selectedEventIndex + 1;
    if (nextIndex < dayEvents.length) {
      setSelectedEventIndex(nextIndex);
      setSelectedEventViewport([dayEvents[nextIndex]]);
    }
  };

  const handleEventDown = (e) => {
    e.stopPropagation();
    setDayIndex(dayIndex + 1);
  };

  const handleEventUp = (e) => {
    e.stopPropagation();
    setDayIndex(dayIndex - 1);
  };

  const getButtonColor = (eventIndex) => {
    if (selectedEventIndex === eventIndex) {
      return `#${dayEvents[eventIndex].color}`;
    } else {
      return lightOrDark(dayEvents[eventIndex].color) ? "#666666" : "#FFFFFF";
    }
  };

  const isButtonDisabled = (eventIndex) => {
    return selectedEventIndex === eventIndex || eventIndex !== selectedEventIndex - 1;
  };

  return (
    <div
      className="border border-gray-200 flex flex-col hover:bg-gray-50 transition-all ease-in-out duration-300 cursor-pointer"
      onClick={() => {
        setDaySelected(day);
      }}
    >
      <header className="flex flex-col items-left font-poppins text-gray-400 font-extrabold">
        {rowIdx === 0 && <p className="text-sm mt-1 text-center text-gray-500">{day.format("ddd").toUpperCase()}</p>}
        <p className={`text-sm p-1 my-1 ml-3 text-left text-gray-400 ${getCurrentDayClass()}`}>{day.format("DD")}</p>
      </header>
      <div className="flex-1">
        <div className="bottom justify-center flex-grow h-30 p-1 w-full">
          {
            dayIndex > 0  ? 
              <div className="flex justify-center items-center cursor-pointer" onClick={(e) => {handleEventUp(e)}}>
                <RiArrowUpSLine/>
              </div>
              :
              <div className="flex justify-center items-center opacity-0">
                <RiArrowUpSLine/>
              </div>
          }
          {
            dayEvents.slice(dayIndex, dayIndex + 3).map((event, index) => (
                <div className="event text-white rounded p-1 mb-1 text-sm cursor-pointer" style={{ backgroundColor: '#' + event.color }} onClick={(e) => {
                  setSelectedEvent(event);
                  setShowEventModal(true);
                }}>
                  <span className="event-name font-poppins font-semibold">
                    {event.title}
                  </span>
                  <span className="time">
                    {event.hour}
                  </span>
                </div>
            ))
          }
          {
            dayEvents.length > 3 + dayIndex ? 
              <div className="flex justify-center items-center cursor-pointer" onClick={(e) => {handleEventDown(e)}}>
                <RiArrowDownSLine/>
              </div>
              :
              <div className="flex justify-center items-center cursor-pointer opacity-0">
                <RiArrowDownSLine/>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Day;