import React, { useContext } from "react";
import { Fragment } from "react";
import { useHistory } from "react-router";
import Navbar from "../components/Navbar";
import PocketBase from 'pocketbase';
const pb = new PocketBase('https://pocketbase.dev.grava.io/');

function HomeScreen() {
  const history = useHistory();
  const user = pb.authStore.isValid;
  return (
    <Fragment>
      <Navbar />
      <div className="container mx-auto pt-60">
        <h1 className="text-4xl text-gray-700 font-bold">
          Grava | Calendario
        </h1>
        <p className="text-xl text-gray-700 pt-4">
          Lorem ipsum dolor sit amet consectetur adipiscing elit libero, posuere inceptos enim habitasse hac ligula.
        </p>
        <p className="text-xl text-gray-700 font-bold">Lorem ipsum</p>
        {user ? (
          <button
            onClick={() => history.push("/calendar")}
            className="border rounded py-2 px-6 mr-5 mt-5 bg-blue-500 text-white hover:bg-blue-600"
          >
            Calendario
          </button>
        ) : (
          <button
            onClick={() => history.push("/sign_in")}
            className="border rounded py-2 px-6 mr-5 mt-5 bg-blue-500 text-white hover:bg-blue-600"
          >
            Ingresar
          </button>
        )}
      </div>
    </Fragment>
  );
}

export default HomeScreen;
