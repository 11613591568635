import React, { useState } from "react";
import { UserLogIn } from "../pocketbase";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

function CalendarScreen() {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const handleLogin = async () => {
    return UserLogIn(history, user, password)
      .then((res) => {
        if (res) {
          return history.push("/calendar");
        }
        toast.error("Usuario o contraseña invalidos")
      });
  }

  return (
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
      <div>
        <Toaster/>
      </div>
      <div class="px-8 py-6 mt-4 text-left bg-white shadow-lg">
        <h3 class="text-2xl font-bold text-center">Login Calendario</h3>
        <form action="">
          <div class="mt-4">
            <div>
              <label class="block" for="email">Usuario</label>
              <input onChange={(e) => {setUser(e.target.value)}} type="text" placeholder="Usuario" class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"/>
            </div>
             <div class="mt-4">
              <label class="block">Contraseña</label>
              <input onChange={(e) => {setPassword(e.target.value)}} type="password" placeholder="Contraseña" class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"/>
            </div>
            <div class="flex items-baseline justify-between">
              <button onClick={(e) => {e.preventDefault(); handleLogin();}} class="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Ingresar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CalendarScreen;
