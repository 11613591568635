import React from "react";
import { Route, Redirect } from "react-router-dom";
import PocketBase from 'pocketbase';
const pb = new PocketBase('https://pocketbase.dev.grava.io/');

function PrivateRoute({ children, ...rest }) {
  const user = pb.authStore.isValid;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;
