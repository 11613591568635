import PocketBase from 'pocketbase';
const pb = new PocketBase('https://pocketbase.dev.grava.io/');

export async function writeEventData(event, type, oldEvent) {
  try {
    if(type === 'update') {
      await pb.collection('fechas').update(oldEvent.id, event);
    } else {
      await pb.collection('fechas').create(event);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteEvent(event) {
  try {
    await pb.collection('fechas').delete(event.id);
  } catch (error) {
    console.error(error);
  }
}
export async function initializeCalendarIO(dispatchCalEvent) {
  await pb.collection('fechas').unsubscribe();
  return pb.collection('fechas').subscribe('*', function (e) {
    getEventsData()
      .then((result) => {
        dispatchCalEvent({ type: "fetch", payload: result });
      })
  });
}
export async function getEventsData() {
  try {
    const records = await pb.collection('fechas').getFullList({
        sort: '-created',
    });
    return records;
  } catch (error) {
    console.error(error);
    return [];
  }
}
