import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import { writeEventData, deleteEvent, getEventsData } from "../database";

function EventModal() {
  const [labelsClasses, setLabelsClasses] = useState([]);
  const { setShowEventModal, daySelected, dispatchCalEvent, selectedEvent, labels } =
    useContext(GlobalContext);

  useEffect(() => {
    const labelClassesMap = labels.map((label) => {
      return label.label
    });
    setLabelsClasses(labelClassesMap);
  }, [labels]);
  
  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  const [error, setError] = useState(null);

  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((label) => label === selectedEvent.label)
      : labelsClasses[0]
  );

  async function handleSubmit(e) {
    e.preventDefault();
    if (!title) {
      setError("The title is required.");
    } else {
      setError(null);
      const event = {
        title,
        description,
        date: daySelected,
        color: selectedLabel,
        event_type: 'normal',
        url: 'https://grava.io'
      };
      
      if (selectedEvent) {
        writeEventData(event, 'update', selectedEvent)
          .then(() => {
            return getEventsData();
          })
          .then((events) => {
            dispatchCalEvent({ type: "fetch", payload: events });
          });
      } else {
        writeEventData(event, 'create')
          .then(() => {
            dispatchCalEvent({ type: "push", payload: event });
          });
      }
      setShowEventModal(false);
    }
  }

  return (
    <div className="h-screen w-full fixed left-0 flex justify-center items-center">
      <form className="bg-white rounded-lg shadow-2xl w-1/4">
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400">
            drag_handle
          </span>
          <div>
            {selectedEvent && (
              <span
                onClick={() => {
                  dispatchCalEvent({ type: "delete", payload: selectedEvent });
                  deleteEvent(selectedEvent);
                  setShowEventModal(false);
                }}
                className="material-icons-outlined text-gray-400 cursor-pointer"
              >
                delete
              </span>
            )}
            <button onClick={() => setShowEventModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div className="flex-1">
            <div></div>
            <input
              type="text"
              name="title"
              placeholder="Agrega un titulo"
              value={title}
              className="pt-3 mb-8 border-0 text-gray-600 text-xl break-normal font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange={(e) => setTitle(e.target.value)}
            />
            <p className="mb-4">{daySelected.format("dddd D MMMM")}</p>
            <textarea
              type="text"
              name="description"
              placeholder="Agrega un descripcion"
              value={description}
              className="block mb-8 h-40 p-12.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="ml-auto flex gap-x-2">
              {labelsClasses.map((lblClass, i) => (
                <span
                  key={i}
                  onClick={() => setSelectedLabel(lblClass)}
                  className={`w-16 h-6 rounded flex items-center justify-center cursor-pointer`}lblClass
                  style={{ backgroundColor: '#' + lblClass }}
                >
                  {selectedLabel === lblClass && (
                    <span className="material-icons-outlined text-sm text-white">
                      check
                    </span>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
        <footer className="flex justify-between items-center border-t p-3 mt-5">
          <small className="text-red-500 ml-1">{error ? error : null}</small>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
          >
            Crear
          </button>
        </footer>
      </form>
    </div>
  );
}

export default EventModal;
