import AuthContext from "./AuthContext";
import { useState } from "react";

function AuthWrapper({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);

  return (
    <AuthContext.Provider
      value={{ user, setUser, loadingAuth, setLoadingAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthWrapper;
