import PocketBase from 'pocketbase';
const pb = new PocketBase('https://pocketbase.dev.grava.io/');

export async function UserLogIn(history, user, password) {
  try {
    await pb.collection('users').authWithPassword(
      user,
      password
    );
    if (pb.authStore.isValid) {
      return true;
    }
  } catch(err) {
    console.error(err);
    return false;
  }
}
export async function UserLogOut(history) {
  try {
    history.push('/')
    return pb.authStore.clear();
  } catch(err) {
    history.push('/')
    return err;
  }
}
