import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { RiAddLine } from "react-icons/ri";
import moment from "moment";
import 'moment/locale/es';
import GlobalContext from "../context/GlobalContext";
moment.locale('es');
function Detailbar() {

  const {
    daySelected,
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
  } = useContext(GlobalContext);

  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const procEvents = filteredEvents.map((event, index) => {
      return event;
    })

    return setUpcomingEvents(procEvents);
  }, [filteredEvents]);
  return (
    <div className="border p-5 w-80 overflow-auto" style={{'max-height': '94vh'}}>
      <h1 className="mb-6 hidden sm:block mr-4 font-poppins text-xl text-gray-500 font-bold cursor-pointer">
        Proximos eventos - {daySelected.format("DD/MM")}
      </h1>
          <a 
            href="#."
            className="mb-2.5 flex justify-center items-center block max-w-sm p-3 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 dark:bg-gray-800 transition-all ease-in-out duration-300 dark:border-gray-700 dark:hover:bg-gray-700"
            onClick={() => {
              setShowEventModal(true);
            }}
          >
              <h5 className="mb-1 text-s flex justify-center items-center font-bold tracking-tight font-poppins text-gray-700 dark:text-white">Crear evento <RiAddLine className="ml-2"/></h5>
          </a>
      {
        upcomingEvents
        .filter(
          (event) => dayjs(event.date).format("DD-MM-YY") === daySelected.format("DD-MM-YY")
        ).map((event, index) => (
          <a 
            href="#."
            className="mb-3.5 block max-w-sm p-6 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-all ease-in-out duration-150 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            onClick={() => {
              setShowEventModal(true);
              setSelectedEvent(event);
            }}
          >
              <h5 className="mb-1 text-xl font-bold tracking-tight font-poppins text-gray-900 dark:text-white">{event.title}</h5>
              <h6 className="mb-2 text-xs font-light tracking-tight text-gray-500 dark:text-white">{moment(event.date).fromNow()}</h6>
              <p className="font-normal text-sm text-gray-700 dark:text-gray-400">{event.description}</p>
          </a>
        ))
      }
    </div>
  );
}

export default Detailbar;
