import React from "react";

function DesktopOnly() {
  return (
    <div className="pt-60 m-5">
      <h1 className="text-4xl text-gray-700 font-bold text-center">
        Google Calendar Clone
      </h1>
      <p className="text-xl text-gray-700 pt-4 text-center">
        This app is currently only usable on desktop.
      </p>
    </div>
  );
}

export default DesktopOnly;
