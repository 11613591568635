import React from "react";
import Labels from "./Labels";
import SmallCalendar from "./SmallCalendar";

function Sidebar() {
  return (
    <div className="border p-5 w-64 divide-y ">
      <SmallCalendar/>
      <div>
        <Labels/>
      </div>
    </div>
  );
}

export default Sidebar;
