import React, { Fragment, useState, useContext } from "react";
import GlobalContext from "../context/GlobalContext";

function Labels() {
  const {
    labels,
    setLabels
  } = useContext(GlobalContext);

  function updateLabel({ text, checked }) {
    setLabels(labels.map((label) => {
      return label.text === text ? { ...label, checked } : label;
    }))
  }
  return (
    <Fragment>
      <p className="text-gray-500 font-bold font-poppins mt-10">Categorias</p>
      {labels.map(({ label, text, checked }, index) => (
        <label key={index} className="items-center text-sm mt-3 block">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => updateLabel({ text, checked: !checked })}
            className={`form-checkbox h-4 w-4 text-${text}-400 rounded focus:ring-0 cursor-pointer`}
            style={checked ? { backgroundColor: '#' + label } : null}
          />
          <span className="ml-2 text-gray-700 capitalize">{text}</span>
        </label>
      ))}
    </Fragment>
  );
}

export default Labels;
